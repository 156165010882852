<template>
  <div class="contact">
    <div class="main-bg">
      <img src="./../assets/contact_main_bg.jpg" alt="ScoreOne" />
    </div>
    <div class="touch">
      <h3>{{$t('Contact.title')}}</h3>
      <form>
        <label>
          <span class="inputName"><i>*</i>{{$t('Contact.form.name')}}</span>
          <input type="text" v-model="form.name" />
          <span class="required" v-show="required"><img src="./../assets/error.png" alt="!">{{$t('Contact.form.name_required')}}</span>
        </label>
        <br />
        <label>
          <span class="inputName"><i>*</i>{{$t('Contact.form.email')}}</span>
          <input type="text" v-model="form.email" />
          <span class="required" v-show="required"><img src="./../assets/error.png" alt="!">{{$t('Contact.form.email_required')}}</span>
        </label>
        <br />
        <label>
          <span class="inputName">{{$t('Contact.form.subject')}}</span>
          <input type="text" v-model="form.subject" />
        </label>
        <br />
        <label>
          <span class="textArea"><i>*</i>{{$t('Contact.form.hint')}}</span>
          <textarea v-model="form.textarea" />
          <span class="required textarea-required" v-show="required"><img src="./../assets/error.png" alt="!">{{$t('Contact.form.message_required')}}</span>
        </label>
      </form>
      <div class="agreement">
        <div class="agreement-content">
          <label>
            <span>{{$t('Contact.form.choose')}}</span><input type="checkbox" v-model="form.invest" />{{$t('Contact.form.option1')}}
          </label><br />
          <label>
            <span></span><input type="checkbox" v-model="form.corporationSolution" />{{$t('Contact.form.option2')}}
          </label><br />
          <label>
            <span></span><input type="checkbox" v-model="form.meidaAndPR" />{{$t('Contact.form.option3')}}
          </label>
        </div>
      </div>
      <div class="submit">
        <span @click="submit">{{$t('Contact.form.submit')}}</span>
      </div>
    </div>
    <transition mode="out-in" enter-active-class="fadeInDown" leave-active-class="fadeOutUp" :duration="{enter: 250,leave: 200}">
      <div class="message" v-show="messageShow">
        <span class="prompt"><img src="./../assets/success.png" />{{$t('Contact.form.message')}}</span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data: function() {
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        textarea: '',
        invest: false,
        corporationSolution: false,
        meidaAndPR: false
      },
      messageShow: false,
      required: false
    }
  },
  methods: {
    submit() {
      const { name, email, textarea } = this.form
      if (!name || !email || !textarea) {
        this.required = true
        setTimeout(() => {
          this.required = false
        }, 2500)
      } else {
        this.messageShow = true
        this.form = {
          name: '',
          email: '',
          subject: '',
          textarea: '',
          invest: false,
          corporationSolution: false,
          meidaAndPR: false
        }
        setTimeout(() => {
          this.messageShow = false
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss">
.contact {
  width: 100%;
  .main-bg {
    width: 100%;
    min-width: 1200px;
    img {
      width: 100%;
    }
  }
  .touch {
    width: 1200px;
    margin: 0 auto;
    > h3 {
      width: 650px;
      margin: 0 auto;
      box-sizing: border-box;
      font: normal bold 32px Arial;
      color: #173e69;
      padding: 75px 0 40px 185px;
    }
    form {
      width: 800px;
      margin: 0 auto 50px;
      label {
        font: normal 500 14px Arial;
        .inputName {
          display: inline-block;
          width: 185px;
          text-align: right;
          color: #3b3b3b;
          i {
            color: #f00;
            margin-right: 2px;
            font-size: 16px;
          }
        }
        .required {
          color: #f00;
          font-size: 15px;
          img {
            width: 15px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
        .textarea-required {
          padding-top: 5px;
          vertical-align: top;
          img {
            vertical-align: top;
          }
        }
        .textArea {
          display: inline-block;
          width: 185px;
          text-align: right;
          vertical-align: top;
          i {
            color: #f00;
            margin-right: 2px;
            font-size: 16px;
          }
        }
        input {
          width: 300px;
          height: 34px;
          margin-bottom: 15px;
          padding: 0 10px;
          background: #ecf4ff;
          border: 1px solid #496686;
          border-radius: 4px;
        }
        textarea {
          width: 400px;
          height: 150px;
          padding: 10px;
          background: #ecf4ff;
          border: 1px solid #496686;
          border-radius: 4px;
        }
      }
    }
    .agreement {
      width: 1200px;
      margin: 0 auto;
      .agreement-content {
        width: 800px;
        margin: 0 auto;
        font: normal 500 14px Arial;
        color: #3b3b3b;
        label {
          span {
            display: inline-block;
            width: 170px;
            text-align: right;
          }
          input {
            margin: 0 10px 20px;
          }
        }
      }
    }
    .submit {
      width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;
      padding-right: 100px;
      text-align: center;
      span {
        display: inline-block;
        background: #5691e9;
        cursor: pointer;
        font: normal bold 16px Arial;
        color: #f7f7f7;
        padding: 12px 35px;
        border-radius: 6px;
        margin: 50px 0 100px;
      }
    }
  }
  .message {
    position: fixed;
    width: 100%;
    top: 5px;
    z-index: 9999;
    text-align: center;
    .prompt {
      height: 30px;
      padding: 8px 16px 8px 0;
      border-radius: 3px;
      box-shadow: 0 3px 10px #aaa;
      font: normal 500 14px/30px Arial;
      img {
        height: 20px;
        margin-left: 20px;
        margin-right: 5px;
      }
    }
  }
}
</style>
